
import { defineComponent } from 'vue'
import Dropdown3 from '@/components/dropdown/Dropdown3.vue'

export default defineComponent({
  name: 'orders',
  components: {
    Dropdown3
  },
  setup () {
    const items = [
      {
        image: 'media/stock/600x400/img-20.jpg',
        title: 'Premium Coffee',
        desc: 'Arabica Specialty Brand'
      },
      {
        image: 'media/stock/600x400/img-25.jpg',
        title: 'Light Sneakers',
        desc: 'The Best Lightweight Sneakers'
      },
      {
        image: 'media/stock/600x400/img-24.jpg',
        title: 'Red Boots',
        desc: 'All Season Boots'
      },
      {
        image: 'media/stock/600x400/img-19.jpg',
        title: 'Wall Decoration',
        desc: 'Creative & Easy To Install'
      },
      {
        image: 'media/stock/600x400/img-27.jpg',
        title: 'Home Confort',
        desc: 'Smart Air Purifier'
      }
    ]

    return {
      items
    }
  }
})
