
import { defineComponent } from 'vue'
import Dropdown1 from '@/components/dropdown/Dropdown1.vue'

export default defineComponent({
  name: 'tasks',
  components: {
    Dropdown1
  },
  setup () {
    const items = [
      {
        icon: 'media/icons/duotune/abstract/abs027.svg',
        color: 'success',
        title: 'Project Briefing',
        description: 'Project Manager'
      },
      {
        icon: 'media/icons/duotune/art/art005.svg',
        color: 'warning',
        title: 'Concept Design',
        description: 'Art Director'
      },
      {
        icon: 'media/icons/duotune/communication/com012.svg',
        color: 'primary',
        title: 'Functional Logics',
        description: 'Lead Developer'
      },
      {
        icon: 'media/icons/duotune/coding/cod008.svg',
        color: 'danger',
        title: 'Development',
        description: 'DevOps'
      },
      {
        icon: 'media/icons/duotune/general/gen049.svg',
        color: 'info',
        title: 'Testing',
        description: 'QA Managers'
      }
    ]

    return {
      items
    }
  }
})
