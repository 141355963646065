import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_sidebar",
  class: "sidebar",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "sidebar",
  "data-kt-drawer-activate": "{default: true, xxl: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'300px', 'lg': '400px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_sidebar_toggler"
}
const _hoisted_2 = {
  class: "d-flex flex-column sidebar-body px-5 py-10",
  id: "kt_sidebar_body"
}
const _hoisted_3 = {
  class: "sidebar-nav nav nav-tabs mb-10",
  id: "kt_sidebar_tabs",
  role: "tablist"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = {
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-kt-countup-tabs": "true",
  href: "#kt_sidebar_tab_1"
}
const _hoisted_6 = { class: "svg-icon" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = {
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-kt-countup-tabs": "true",
  href: "#kt_sidebar_tab_2"
}
const _hoisted_9 = { class: "svg-icon" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = {
  class: "nav-link active",
  "data-bs-toggle": "tab",
  "data-kt-countup-tabs": "true",
  href: "#kt_sidebar_tab_3"
}
const _hoisted_12 = { class: "svg-icon" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = {
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-kt-countup-tabs": "true",
  href: "#kt_sidebar_tab_4"
}
const _hoisted_15 = { class: "svg-icon" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = {
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-kt-countup-tabs": "true",
  href: "#kt_sidebar_tab_5"
}
const _hoisted_18 = { class: "svg-icon" }
const _hoisted_19 = { id: "kt_sidebar_content" }
const _hoisted_20 = {
  class: "hover-scroll-y",
  "data-kt-scroll": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0px",
  "data-kt-scroll-dependencies": "#kt_sidebar_tabs",
  "data-kt-scroll-wrappers": "#kt_sidebar_content, #kt_sidebar_body"
}
const _hoisted_21 = { class: "tab-content px-5 px-xxl-10" }
const _hoisted_22 = {
  class: "tab-pane fade",
  id: "kt_sidebar_tab_1",
  role: "tabpanel"
}
const _hoisted_23 = {
  class: "tab-pane fade",
  id: "kt_sidebar_tab_2",
  role: "tabpanel"
}
const _hoisted_24 = {
  class: "tab-pane fade show active",
  id: "kt_sidebar_tab_3",
  role: "tabpanel"
}
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "kt_sidebar_tab_4",
  role: "tabpanel"
}
const _hoisted_26 = {
  class: "tab-pane fade",
  id: "kt_sidebar_tab_5",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Stats = _resolveComponent("Stats")!
  const _component_Tasks = _resolveComponent("Tasks")!
  const _component_Orders = _resolveComponent("Orders")!
  const _component_BestSellers = _resolveComponent("BestSellers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs038.svg" })
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_7, [
          _createElementVNode("a", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs042.svg" })
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_10, [
          _createElementVNode("a", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs036.svg" })
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_13, [
          _createElementVNode("a", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod001.svg" })
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_16, [
          _createElementVNode("a", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs026.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_Stats, {
                title: "Assigned Tasks",
                "stat1-value": "100",
                "stat1-label": "Pending",
                "stat2-value": "210",
                "stat2-label": "Completed",
                "stat3-value": "10",
                "stat3-label": "On Hold",
                "stat4-value": "55",
                "stat4-label": "In Progress"
              }),
              _createVNode(_component_Tasks)
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Stats, {
                title: "Customer Orders",
                "stat1-value": "40",
                "stat1-label": "In Process",
                "stat2-value": "110",
                "stat2-label": "Delivered",
                "stat3-value": "29",
                "stat3-label": "On Hold",
                "stat4-value": "15",
                "stat4-label": "In Progress"
              }),
              _createVNode(_component_Orders)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_Stats, {
                title: "Support Tickets",
                "stat1-value": "28",
                "stat1-label": "Pending",
                "stat2-value": "204",
                "stat2-label": "Completed",
                "stat3-value": "76",
                "stat3-label": "On Hold",
                "stat4-value": "9",
                "stat4-label": "In Progress"
              }),
              _createVNode(_component_BestSellers)
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_Stats, {
                title: "Notifcations",
                "stat1-value": "5",
                "stat1-label": "System Alert",
                "stat2-value": "10",
                "stat2-label": "Server Failure",
                "stat3-value": "40",
                "stat3-label": "User Feedback",
                "stat4-value": "3",
                "stat4-label": "Backup"
              }),
              _createVNode(_component_Tasks)
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_Stats, {
                title: "Outgoing Emails",
                "stat1-value": "160",
                "stat1-label": "Sending",
                "stat2-value": "2,600",
                "stat2-label": "Sent",
                "stat3-value": "2,500",
                "stat3-label": "Delivered",
                "stat4-value": "11",
                "stat4-label": "Failed"
              }),
              _createVNode(_component_Tasks)
            ])
          ])
        ])
      ])
    ])
  ]))
}