
import { defineComponent } from 'vue'
import Dropdown3 from '@/components/dropdown/Dropdown3.vue'

export default defineComponent({
  name: 'best-sellers',
  components: {
    Dropdown3
  },
  setup () {
    const items = [
      {
        image: 'media/stock/600x400/img-1.jpg',
        title: 'Spotify App',
        desc: 'HTML, SASS, Bootstrap'
      },
      {
        image: 'media/stock/600x400/img-2.jpg',
        title: 'Fitnes Drive',
        desc: 'Angular, Typescript, Bootstrap'
      },
      {
        image: 'media/stock/600x400/img-3.jpg',
        title: 'Taskify App',
        desc: 'HTML, CSS. jQuery'
      },
      {
        image: 'media/stock/600x400/img-5.jpg',
        title: 'Calendr App',
        desc: 'React, MangoDb. Node'
      },
      {
        image: 'media/stock/600x400/img-6.jpg',
        title: 'Stocked SaaS',
        desc: 'PHP, Laravel, Oracle'
      }
    ]

    return {
      items
    }
  }
})
