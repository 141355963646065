import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-flush card-p-0 shadow-none bg-transparent mb-10" }
const _hoisted_2 = { class: "card-header align-items-center border-0" }
const _hoisted_3 = { class: "card-title fw-bolder text-white fs-3" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-icon btn-icon-white btn-active-color-primary me-n4",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row g-5" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 p-xxl-5" }
const _hoisted_11 = { class: "text-white fs-2 fs-xxl-2x fw-bolder mb-1" }
const _hoisted_12 = { class: "sidebar-text-muted fs-6 fw-bold" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 p-xxl-5" }
const _hoisted_15 = { class: "text-white fs-2 fs-xxl-2x fw-bolder mb-1" }
const _hoisted_16 = { class: "sidebar-text-muted fs-6 fw-bold" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 p-xxl-5" }
const _hoisted_19 = { class: "text-white fs-2 fs-xxl-2x fw-bolder mb-1" }
const _hoisted_20 = { class: "sidebar-text-muted fs-6 fw-bold" }
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = { class: "sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 p-xxl-5" }
const _hoisted_23 = { class: "text-white fs-2 fs-xxl-2x fw-bolder mb-1" }
const _hoisted_24 = { class: "sidebar-text-muted fs-6 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.stat1Value), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.stat1Label), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.stat2Value), 1),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.stat2Label), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.stat3Value), 1),
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.stat3Label), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.stat4Value), 1),
            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.stat4Label), 1)
          ])
        ])
      ])
    ])
  ]))
}