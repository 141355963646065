import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-kt-sticky"]
const _hoisted_2 = { class: "d-flex d-lg-none align-items-center ms-n2 me-2" }
const _hoisted_3 = {
  class: "btn btn-icon btn-active-icon-primary",
  id: "kt_aside_toggle"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1 mt-1" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }
const _hoisted_7 = {
  class: "d-flex align-items-stretch",
  id: "kt_header_nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_header",
    class: "header align-items-stretch",
    "data-kt-sticky": _ctx.isHeaderSticky,
    "data-kt-sticky-name": "header",
    "data-kt-sticky-offset": "{default: '200px', lg: '300px'}"
  }, [
    _createElementVNode("div", {
      id: "kt_header_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex flex-stack flex-wrap gap-2"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs015.svg" })
          ])
        ]),
        _createVNode(_component_router_link, {
          to: "/dashboard",
          class: "d-flex align-items-center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.themeLightLogo,
              class: "h-20px"
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_PageTitle, {
        breadcrumbs: _ctx.breadcrumbs,
        title: _ctx.title
      }, null, 8, ["breadcrumbs", "title"]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_KTMenu)
        ])
      ])
    ], 2)
  ], 8, _hoisted_1))
}