
import { defineComponent } from 'vue'
import Stats from '@/components/widgets/sidebar-widgets/Stats.vue'
import BestSellers from '@/components/widgets/sidebar-widgets/BestSellers.vue'
import Tasks from '@/components/widgets/sidebar-widgets/Tasks.vue'
import Orders from '@/components/widgets/sidebar-widgets/Orders.vue'

export default defineComponent({
  name: 'sidebar',
  components: {
    Stats,
    BestSellers,
    Tasks,
    Orders
  }
})
