
import { defineComponent, computed } from 'vue'
// import KTTopbar from '@/layout/header/Topbar.vue'
import PageTitle from '@/layout/page-title/PageTitle.vue'
import KTMenu from '@/layout/header/Menu.vue'

import {
  headerWidthFluid,
  headerFixed,
  headerFixedOnMobile,
  headerLeft,
  asideDisplay,
  themeLightLogo
} from '@/core/helpers/config'

export default defineComponent({
  name: 'KTHeader',
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    // KTTopbar,
    KTMenu,
    PageTitle
  },
  setup () {
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value
      } else {
        return headerFixedOnMobile.value
      }
    })

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      themeLightLogo,
      isHeaderSticky
    }
  }
})
