
import { defineComponent } from 'vue'
import Dropdown2 from '@/components/dropdown/Dropdown2.vue'

export default defineComponent({
  name: 'stats',
  components: {
    Dropdown2
  },
  props: {
    title: String,
    stat1Label: String,
    stat1Value: String,
    stat2Label: String,
    stat2Value: String,
    stat3Label: String,
    stat3Value: String,
    stat4Label: String,
    stat4Value: String
  }
})
