
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'kt-page-title',
  props: {
    breadcrumbs: Array,
    title: String
  },
  component: {}
})
