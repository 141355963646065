const DocMenuConfig = [

  {
    heading: 'Dashboard',
    route: '/dashboard',
    svgIcon: '/media/icons/duotune/arrows/arr001.svg',
    fontIcon: 'bi-layers'
  },
  {
    sectionTitle: 'craft',
    heading: 'Companies',
    route: '/companies',
    svgIcon: '/media/icons/duotune/arrows/arr001.svg',
    fontIcon: 'bi-app-indicator'
  },
  {
    sectionTitle: 'craft',
    heading: 'Accounts',
    route: '/accounts',
    svgIcon: '/media/icons/duotune/arrows/arr001.svg',
    fontIcon: 'bi-app-indicator'
  },
  {
    sectionTitle: 'Statistics',
    heading: 'Statistics',
    route: '/statistics',
    svgIcon: '/media/icons/duotune/arrows/arr001.svg',
    fontIcon: 'bi-app-indicator',
    pages: [
      {
        heading: 'Leads',
        route: '/statistics/leads'
      },
      {
        heading: 'Users',
        route: '/statistics/users'
      }
    ]
  }

]

export default DocMenuConfig
