
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n/index'
import KTMenu from '@/layout/aside/Menu.vue'
import UserMenu from '@/layout/header/partials/UserMenu.vue'
import { asideTheme, themeLightLogo } from '@/core/helpers/config'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'KTAside',
  components: {
    KTMenu,
    UserMenu
  },
  props: {
    lightLogo: String,
    darkLogo: String
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const currentAccount = () => {
      return store.getters.currentAccount
    }

    return {
      asideTheme,
      themeLightLogo,
      currentAccount,
      t
    }
  }
})
